<template>
	<!-- 土地工单信息 -->
	<div class="land-work-order el-content">
		<a-tabs v-model:activeKey="active" size="small">
		    <a-tab-pane key="work" tab="工单列表">
				<a-space style="margin-bottom: 12px;">
					<a-select v-model:value="search.server_id" style="width: 120px" >
					    <a-select-option value="">全部</a-select-option>
					    <a-select-option v-for="(val,ind) in service.list" :key="ind" :value="val.id">
							{{ val.title }}
						</a-select-option>
					</a-select>
					<a-select v-model:value="search.order_status" style="width: 120px" >
					    <a-select-option value="">全部</a-select-option>
					    <a-select-option value="1">待分配</a-select-option>
					    <a-select-option value="2">进行中</a-select-option>
					    <a-select-option value="3">已完成</a-select-option>
					</a-select>
					<a-input-search
						placeholder="输入工单号,工人姓名,手机号"
						enter-button="搜索"
						style="width: 400px;"
						v-model:value="search.key"
						@search="getWorkList(1,work.limit)"
					/>
				</a-space>
				<a-table row-key="id" :pagination="false" :data-source="work.list" :columns="[
					{dataIndex:'id',title:'ID'},
					{dataIndex:'server.title',title:'工单类型'},
					{dataIndex:'serverdetails.title',title:'操作详情'},
					{dataIndex:'object',title:'操作对象',slots:{customRender:'object'}},
					{dataIndex:'area',title:'面积',slots:{customRender:'area'}},
					{dataIndex:'order_status',title:'订单状态',slots:{customRender:'order_status'}},
					{dataIndex:'workers',title:'工人',slots:{customRender:'workers'}},
					{dataIndex:'action',title:'操作',slots:{customRender:'action'}},
				]" >
				    <template #object="{record}">
						<span v-if="record.land">{{ record.land.land_name }} {{ record.land_spec.land_num }}</span>
					</template>
					<template #area="{record}">
						<span  v-if="record.land_spec">{{ record.land_spec.area }}㎡</span>
					</template>
					<template #order_status="{record}">
						<a-tag color="#f50" v-if="record.order_status == 1">待分配</a-tag>
						<a-tag color="#2db7f5" v-if="record.order_status == 2">进行中</a-tag>
						<a-tag color="#87d068" v-if="record.order_status == 3">已完成</a-tag>
					</template>
					<template #workers="{record}">
						{{ record.workers ? record.workers.name :"--"}}
					</template>
					<template #action="{record}">
						<a-button type="link" size="small" 
							@click="showSetWorker(record.id)" 
							v-has="{action:'wxapp_land_set_work_list',plat:isShop}">指派工单
						</a-button>
						<a-button type="link" size="small" 
							v-has="{action:'wxapp_land_print_work',plat:isShop}" 
							@click="showPrint(record)">打印
						</a-button>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="work.page"
						:total="work.count"
						@showSizeChange="(p,e)=>{getWorkList(work.page,e)}"
						@change="(e)=>{getWorkList(e,work.limit)}"
					/>
				</div>
		    </a-tab-pane>
			
			<a-tab-pane key="worker" tab="工人管理">
				<page-worker :is-shop="isShop" :work-type="2"></page-worker>
			</a-tab-pane>
			
			<a-tab-pane key="set" tab="设置">
				<a-table :data-source="service.list" :pagination="false" row-key="id" :columns="[
					{dataIndex:'id',title:'ID'},
					{dataIndex:'images',title:'封面',slots:{customRender:'images'}},
					{dataIndex:'title',title:'操作名称'},
					{dataIndex:'details',title:'操作详情',slots:{customRender:'details'}},
					{dataIndex:'status',title:'状态',slots:{customRender:'status'}},
					{dataIndex:'action',title:'操作',slots:{customRender:'action'}},
				]" style="margin-top: 12px;">
				    <template #images="{record}">
						<a-image :src="record.images" style="width: 50px;height: 50px;"/>
					</template>
					<template #details="{record}">
						<div v-for="(val,ind) in record.details" :key="ind">操作项{{ind+1}}：{{ val.title }}</div>
					</template>
					<template #status="{record}">
						<a-tag :color="record.status == 1 ?'#87d068':'#f50'">{{ record.status ==1 ? '正常':'隐藏'}}</a-tag>
					</template>
					<template #action="{record}">
						<a-space>
							<router-link :to="{path:isShop == 0 ?'/land/addWorkService':'/many_store/land/addWorkService',query:{id:record.id}}">
								<kd-button type="primary" title="编辑" 
									icon="ri-edit-line" 
									v-has="{action:'/land/addWorkService',plat:isShop}">
								</kd-button>
							</router-link>
							<kd-button type="danger" 
								title="删除" 
								icon="ri-delete-bin-5-line" 
								@click="delService(record.id)" 
								v-has="{action:'wxapp_land_del_server',plat:isShop}">
							</kd-button>
						</a-space>
					</template>
				</a-table>
			</a-tab-pane>

			<template #tabBarExtraContent>
				<router-link :to="{path:isShop == 0 ?'/land/addWorkService':'/many_store/land/addWorkService'}">
					<a-button type="primary" 
						v-if="active== 'set'" 
						v-has="{action:'/land/addWorkService',plat:isShop}" >
						<i class="ri-add-line"></i>添加操作类型
					</a-button>
				</router-link>
			</template>
		</a-tabs>

		<a-modal title="指派工单" :visible="show.setworker" @ok="saveSetWorker" @cancel="show.setworker = false" width="600px">
		    <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
		        <a-form-item label="选择工人">
					<a-select v-model:value="workerForm.user_id" placeholder="please select your zone">
					    <a-select-option v-for="(val,ind) in workerList" :key="ind" :value="val.id">{{ val.name }}</a-select-option>    
					</a-select>
		        </a-form-item>
			</a-form>  
		</a-modal>
		
		<a-modal title="打印工单" :visible="show.print" @ok="printWork" @cancel="show.print = false" width="400px">
			<div class="print-info" id="printWork" v-if="printInfo">
				<div class="print-info-title">{{ printInfo.land_spec.alias }} {{ printInfo.land_spec.land_num }}</div>
				<div class="print-info-txt"><span>工单单号：{{ printInfo.order_number }}</span></div>
				<div class="print-info-txt"><span>操作对象：{{ printInfo.land_spec.alias }}</span></div>
				<div class="print-info-txt"><span>操作类型：{{ printInfo.server.title }}</span></div>
				<div class="print-info-txt"><span>操作内容：{{ printInfo.serverdetails.title }}</span></div>
				<div class="print-info-txt"><span>操作费用：{{ printInfo.total_price }}</span></div>
				<div class="print-info-txt"><span>备注：{{ printInfo.remark }}</span></div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import print from 'print-js'
import comSelectUser from '@/components/miniapp/com-select-user.vue'
import pageWorker from '@/components/layout/common/page-worker.vue'
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
export default{
	name:"com-wrod-order",
	components:{
		comSelectUser,
		pageWorker
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			active:"work",
			search:{
				key:"",
				server_id:"",
				order_status:"",	//1待分配 2进行中 3已完成
			},
			work:{
				list:[],
				page:1,
				limit:10
			},
			service:{
				list:[],
				page:1,
				limit:10
			},
			workerList:[],
			workerForm:{
				id:0,
				user_id:""
			},
			show:{
				print:false,
				setworker:false
			},
			printInfo:null
		})
		getWorkList(1,_d.work.limit)
		getWorkService(1,_d.service.limit)
		landModel.getInspector(1,99,{type:2},res=>_d.workerList = res.list)
		function getWorkList(page,limit){
			landModel.getWorkOrder(page,limit,_d.search,res=>_d.work = {limit,...res})
		}

		function getWorkService( page,limit ) {
			landModel.getLandWorkServer(page,limit,null,res=> _d.service = {limit,...res})
		}

		function showSetWorker(id){
			_d.workerForm.id = id
			_d.show.setworker = true
		}

		function showPrint(data){
			_d.printInfo = data
			_d.show.print = true
		}

		function printWork(){
			printJS({
				printable: 'printWork', // 标签元素id
				type: 'html',
				header: '',
			})
		}
		const saveSetWorker = () => landModel.setWorkOrderWorker(_d.workerForm.user_id,_d.workerForm.id,()=>{
			getWorkList(_d.work.page,_d.work.limit)
			_d.show.setworker =false
		})

		const delService = (id)=>landModel.deleteLandWorServer(id,()=>getWorkService(_d.service.page,_d.service.limit))

		return {
			...toRefs(_d),
			getWorkList,
			showSetWorker,
			saveSetWorker,
			showPrint,
			printWork,
			getWorkService,
			delService
		}
	},
}
</script>
<style lang="scss">
	.land-work-order{
		.search{
			display: flex;
			align-items: center;
			margin-bottom: 24px;
		}
		
		.print-info{
			&-title{
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 12px;
			}
			
			&-txt{
				color: #999;
				font-size: 14px;
				margin-bottom: 6px;
			}
		}
	}
</style>
