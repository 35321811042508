<template>
    <div class="worker">
        <a-space class="mb12">
            <a-input-search
                placeholder="工人姓名"
                enter-button="搜索"
                style="width: 400px;"
                v-model:value="search.key"
                @search="getWorkList(1,work.limit)"
            />
            <com-worker-add :work-type="2" :is-shop="isShop" @success="getWorker(info.page,info.limit)"></com-worker-add>
        </a-space>
        <a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
            {dataIndex:'id',title:'ID'},
            {dataIndex:'account_id',title:'user_id'},
            {dataIndex:'name',title:'姓名'},
            {dataIndex:'mobile',title:'电话'},
            {dataIndex:'in_delivery_num',title:'已接工单'},
            {dataIndex:'quantityorder',title:'已完成工单'},
            {dataIndex:'status',title:'状态',slots:{customRender:'status'}},
            {dataIndex:'action',title:'操作',slots:{customRender:'action'}},
        ]" style="margin-top: 12px;">
            <template #status="{record}">
                <a-tag :color="record.status == 1 ? '#87d068' :'#f50'">{{ record.status == 1 ?'正常':'禁用'}}</a-tag>
            </template>
            <template #action="{record}">
                <a-space>
                    <com-worker-add 
                        :work-type="2" 
                        :work-data="record" 
                        :is-shop="isShop" 
                        :custom="true" 
                        @success="getWorker(info.page,info.limit)">
                        <kd-button type="primary" 
                            title="编辑" 
                            icon="ri-edit-line" 
                            v-has="{action:'wxapp_land_edit_worker',plat:isShop}">
                            
                        </kd-button>
                    </com-worker-add>
                    <kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" @click="delWorker(record.id)" v-has="{action:'wxapp_land_del_worker',plat:isShop}"></kd-button>
                </a-space>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getWorker(info.page,e)}"
                @change="(e)=>{getWorker(e,info.limit)}"
            />
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
import comWorkerAdd from '@/components/layout/common/com-worker-add.vue'
export default {
    name:'page-worker',
    props:{
        isShop:{
            type:Number,
            default:0
        },
        workType:{  //1巡检员 2工人
            type:Number,
            default:1
        }
    },
    components:{
        comWorkerAdd
    },
    setup(props) {
        const _d = reactive({
            search:{key:'',type:props.workType},
            info:{
                list:[],
                page:1,
                count:0,
                limit:10
            },
        })
        getWorker(1,_d.info.limit)
        function getWorker(page,limit){
            landModel.getInspector(page,limit,_d.search,res=>_d.info = {limit,...res})
        }
        const delWorker = (id)=> landModel.deleteInspector(id,()=>getWorker(_d.info.page,_d.info.limit))

        return{
            ...toRefs(_d),
            getWorker,
            delWorker
        }
    },
}
</script>